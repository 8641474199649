/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

const SPACE_URL = `${process.env.REACT_APP_ASSETS_URL}/fridao1-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Mesh_0003: THREE.Mesh
  }
  materials: {
    ['material_0.004']: THREE.MeshBasicMaterial
  }
}

export default function MeetingRoom({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials } = useGLTF(SPACE_URL, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null} rotation={[0,0.3,0]}>
      <mesh castShadow receiveShadow geometry={nodes.Mesh_0003.geometry} material={materials['material_0.004']} />
    </group>
  )
}

useGLTF.preload(SPACE_URL)
